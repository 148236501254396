import React from 'react'
import Helmet from 'react-helmet'
import styled from "styled-components"
import SignUpForm from '../components/SignUp'
import Box from '../components/Box'
import Header from '../components/Header'

import { graphql } from 'gatsby'

const BackgroundBox = styled(Box)`
  background-color: #4844a3;
  min-height: calc(100vh - 159px);
`
const breadcrumbs = [
  { title: 'Course overview', path: '/' },
  {
    title: 'Sign Up',
    path: '/signup'
  }
]

const SignUpPage = ({ data, location }) => (
  <BackgroundBox>
    <Helmet title='Sign Up' />
    <Header breadcrumbs={breadcrumbs} />
    <SignUpForm location = {location}/>
  </BackgroundBox>
)
export const query = graphql`  
  query {    
    site {      
      siteMetadata {        
        languages {
          defaultLangKey
          langs
        }
      }    
    }  
  }
`


export default SignUpPage
